import { render, staticRenderFns } from "./san-zhu-tu.vue?vue&type=template&id=454dc21e&scoped=true"
import script from "./san-zhu-tu.vue?vue&type=script&lang=js"
export * from "./san-zhu-tu.vue?vue&type=script&lang=js"
import style0 from "./san-zhu-tu.vue?vue&type=style&index=0&id=454dc21e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "454dc21e",
  null
  
)

export default component.exports