<template>
  <div class="header_bgi2 w_full h_50 lh_40 text_center bgs_full">
    <div 
      class="fs_26 fw_bold color_fff cursor font_zh_en" 
      @click="goToMenuPage"
    >
      {{ headerName }}
    </div> 
  </div>
</template>

<script>
  export default {
    name: 'BigScreenHeader',
    props: {
      headerName: ''
    },
    data() {
      return {

      }
    },
    methods: {
      // 跳转到 首页
      goToMenuPage() {
        this.$router.push({
          path: "/"
        })
      },
    }
  }
</script>

