// 大屏 Header
import BigScreenHeader from '@/common/header/index.vue'

import Casecader from '@/common/casecader.vue';

import Echart from '@/components/div/echart.vue';

import DataChart from '@/components/data-chart/index.vue';

import WordMap from '@/components/word-map/index.vue';
import WordMap2 from '@/components/word-map/index-map.vue';

export default {
  components: {
    BigScreenHeader,
    Casecader,
    Echart,
    DataChart,
    WordMap,
    WordMap2
  }
}