<template>
  <div class="box">
    <div class="chart_box">
      <v-chart :options="options" autoresize @click="clickChart"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "LeiDaTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    ispreview: Boolean,
  },
  data() {
    return {
      options: {
        title: {
          text: '',
          top: '0',
          left: '40px',
          show: true,
          textStyle: {
            color: '#fff'
          }
        },
        darkMode: 'auto',
        colorBy: 'series',
        grid: {
          left: "0%",
          right: "0%",
          top: "3%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {
          top: 0,
          // right: 20,
          textStyle: {
            color: '#fff'
          }
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '蒙语', max: 100 },
            { name: '数学', max: 100 },
            { name: '汉语', max: 100 },
            { name: '英语', max: 100 },
            { name: '生物', max: 100 },
            { name: '地理', max: 100 },
            { name: '物理', max: 100 },
            { name: '化学', max: 100 },
            { name: '政治', max: 100 },
            { name: '历史', max: 100 }
          ],
          splitNumber: 5, // 雷达图圈数设置
          axisLine: {  // 设置雷达图中间射线的颜色
            lineStyle: {
              // color: '#c0c0c0',
            },
          },
          splitArea : {  //设置图表颜色，show的值为true
            show : true,
            areaStyle : {
              color:"#fff", //一般设置方式
            }
          },
          splitLine : { //网格颜色设置
            show : true,
            lineStyle : {
              width : 1,
              color : '#999',
            },
          },
        },
        series: [
          {
            data: [
              {
                value: [],
                name: ''
              }
            ],
            type: "radar",
            itemStyle: { //该数值区域样式设置
              normal: {
                color: '', //背景颜色，还需设置areaStyle
                lineStyle: {
                  color: 'rgb(115, 248, 157)', //边框颜色
                },
              },
            },
            label: {  //显示value中具体的数值
              normal: {
                show: true,
                textStyle:{  //更改数值样式
                  color: '#000'
                }
              },
            },  
            // areaStyle: { //设置区域背景颜色透明度
            //   normal: {
            //     width: 1,
            //     opacity: 0.8,
            //   },
            // },                
          }
        ],
      },
    };
  },
  watch: {
    value(val) {
      this.options.series[0].data[0].value = val.data.value
      this.options.series[0].data[0].name = val.data.name
    }
  },
  mounted() {
   
  },
  methods: {
    clickChart(params) {
      this.$emit('emitChartParams', params.name)
    }
  }
};
</script>

<style scoped lang="less">
.s { color:rgb(115, 248, 157); }
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.chart_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; bottom: 0px; color: #fff; font-size: 14px; }
</style>
