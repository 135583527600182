<template>
  <div class="box">
    <div class="chart_box">
      <v-chart :options="options" autoresize @click="clickChart" />
    </div>

    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>

export default {
  name: "ZhuZhuangTuX",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    ispreview: Boolean
  },
  data() {
    return {
      unit: '',
      vaccinumList: [],
      animalList: [],
      options: {
        title: {
          text: '',
          top: '',
          left: '40px',
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: '30px'
          }
        },
        grid: {
          left: "2%",
          right: "15%",
          top: "5%",
          bottom: "0%",
          containLabel: true,
        },
        legend: {},
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.9],
          data: [],
          name: '',
          splitLine: true,
          axisTick: {
		        alignWithLabel: true, //柱状图中心是否对齐刻度位置
		        show: false //不显示刻度
		      },
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff",
              fontSize: 20
            }
          }
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            width: 150,
            overflow: 'break',
            textStyle: {
              color: "#000",
              fontSize: 15,
              fontWeight: '500'
            },
            // rotate: -45
          },

        },
        series: [
          {
            data: [],
            type: "bar",
            barGap: "50%",
            barCategoryGap: "50%",
            barWidth: 6,
            color: '',
            // 标签
            label: {
              show: true,
              position: "right",
              distance: 10,
              fontSize: 16,
              color: '#fff',
              fontWeight: 600,
              formatter: function(params) {
                function numberWithCommas(x) {
                  x = x.toString();
                  var pattern = /(-?\d+)(\d{3})/;
                  while (pattern.test(x))
                    x = x.replace(pattern, "$1,$2");
                  return x;
                }
                let val = numberWithCommas(params.value)
                return `{span|${val}}`
              },
              rich: {
                span: {
                  color: '#000',
                  fontSize: '16px',
                  fontWeight: '500',
                  fontFamily: 'font_number1',
                  letterSpacing: '2px'
                }
              }
            },
          }
        ],
      },
    };
  },
  watch: {
    value(val) {
      // this.options.series[0].color = val.color
      this.options.xAxis.data = val.y
      this.options.yAxis.data = val.x
      this.options.series[0].data = val.y
      this.unit = val.unit
    }
  },
  mounted() {
    // let val = JSON.parse(this.$route.params.val)
    // this.options.title.text = val.title
    // this.options.xAxis.data = val.x
    // this.options.yAxis.data = val.y
    // this.options.yAxis.name = val.yName
    // this.options.series[0].data = val.y
  },
  methods: {
    clickChart(params) {
      if (this.vaccinumList && this.vaccinumList.length) {
        this.openVaccinumDetailPage(params)
      } else {
        this.clickYangYiYuanJob(params)
      }
    },

    // 打开 疫苗详情页
    openVaccinumDetailPage(params) {
      let id = ''
      let obj = {}

      this.vaccinumList.forEach(item => {
        if (params.name.substring(0, params.name.length -2) == item.name) {
          id = item.id
        }
      })

      obj = {
        id,
        name: params.name
      }

      this.$emit('emitChartParams', obj)
    },

    // 点击的 是 防疫员的 防疫工作
    clickYangYiYuanJob(params) {
      this.$emit('emitChartParams', params.name)
    }
  }
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.chart_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; top: 0px; color: #000; font-size: 14px; }

.s { color: rgb(6, 70, 246)}
</style>
