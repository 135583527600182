import request from '@/util/request'

function filterNUll(d) {
    let param2 = {};
    for (let k in d){
      // console.log(d[k])
      if(d[k] != null){
        param2[k] = d[k];
      }
    }
    return param2;
}
  
function makeParams(d) {
    var list = []
    var paramList = Object.entries(d);
    for (let k in paramList){
        // console.log(k, paramList[k]);
        list.push(`${paramList[k][0]}=${paramList[k][1]}`);
    } 
    return list.join("&")
}

// 查询 Hs6 编码
export function queryHs6(params) {
    let p = filterNUll(params)
    let query = makeParams(p)

    return request({
        url: `/bigdata/demo/quanqiu/queryHs6?${query}`,
        method: 'get',
    })
}

// 获取 商品信息
export function getProductInfo(hs6) {
    return request({
        url: `/bigdata/demo/hscode/info?hs6=${hs6}`,
        method: 'get',
    })
}

// 获取 监管 详情
export function getJianGuanInfo(hs10) {
    return request({
        url: `/bigdata/demo/hscode/detail?hs10=${hs10}`,
        method: 'get',
    })
}

// 获取 所有 国家
export function getCountryList() {
    return request({
        url: `/bigdata/demo/country/list`,
        method: 'get',
    })
}

// 商品的 中国 进出口
export function chinaQueryHs6(hs6) {
    return request({
        url: `/bigdata/demo/china/queryHs6?hs6=${hs6}`,
        method: 'get',
    })
}

// 商品的 其他国 进口
export function countryIedQueryHs6(hs6) {
    return request({
        url: `/bigdata/demo/trade/ied/queryHs6?hs6=${hs6}`,
        method: 'get',
    })
}

// 商品的 其他国 出口
export function countryEedQueryHs6(hs6) {
    return request({
        url: `/bigdata/demo/trade/eed/queryHs6?hs6=${hs6}`,
        method: 'get',
    })
}

// 获取 【边民互市】【二连口岸】 进口 出口 数据
export function getTradeData(hs6) {
    return request({
        url: `/bigdata/demo/trade/queryHs6?hs6=${hs6}`,
        method: 'get',
    })
}

// 俄罗斯 出口
export function rteQueryHs6(hs6) {
    return request({
        url: `/bigdata/demo/rte/queryHs6?hs6=${hs6}`,
        method: 'get',
    })
}

// 俄罗斯 进口
export function rtiQueryHs6(hs6) {
    return request({
        url: `/bigdata/demo/rti/queryHs6?hs6=${hs6}`,
        method: 'get',
    })
}



