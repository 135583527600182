<template>
  <div class="w_full_i">
    <el-cascader
      class="w_full_i"
      :props="props"
      size="small"
      v-model="selectedOptions"
      placeholder="请选择行政区域"
      clearable
      @change="handleChange"
    ></el-cascader>
  </div>
</template>

<script>

export default {
  name: 'cascaderArea',
  props: {
    casecaderClearable: false
  },
  data() {
    return {
      townList: [],
      selectedOptions: [],

      props: {
        multiple: false, 
        checkStrictly: true,
        lazy: true,
        lazyLoad: (node, resolve) => {
          let { level } = node;
          const nodes = []  // 动态节点

          let areaCode = null

          if (!level) {
            areaCode = "150423"
            level = 3
          } else {
            areaCode = node.value
            level = level + 3
          }

          // console.log('level : ', level);

          if (level >= 6) return false

          this.getAdminAreaList(areaCode, level)
          .then((res) => {
            res.map((item) => {
              let area = {
                value: item.code,
                label: item.name,
                leaf: node.level >= 6,
              };
              nodes.push(area)
            });
            resolve(nodes) // 回调
          })
          .catch((error) => {
            console.log(error)
          })
        },
      }
    }
  },
  watch: {
    casecaderClearable(newVal) {
      if (newVal) {
        this.selectedOptions = []
      }
    }
  },
  mounted() {

  },
  methods: {
    // 获取 区域
    async getAdminAreaList(code, level) {
      let res = await getAdminAreaList(code, level)
      try {
        if (res.code == 1000) {
          return res.data 
        }
      } catch(err) {
        this.$message.error(err);
      }
    },

    // 选择的行政区
    handleChange(list) { 
      this.$emit('getSelectedOptions', list)
    }
  }
}
</script>
