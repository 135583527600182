<template>
  <div class="box">
    <div class="chart_box">
      <v-chart :options="options" autoresize @click="clickChart"/>
    </div>

    <span class="unit" v-if="isShowUnit">单位: {{ unit }}</span>
  </div>
</template>

<script>

export default {
  name: "ZhuZhuangTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    ispreview: Boolean,
    isShowUnit: true
  },
  data() {
    return {
      unit: '',
      options: {
        title: {
          text: '',
          top: '0',
          left: '40px',
          show: true,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: "0%",
          right: "0%",
          top: "3%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {},
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 14
            },
            // rotate: -45
          },
        },
        yAxis: {
          type: "value",
          data: [],
          name: '',
          splitLine: false,
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff",
              fontSize: 20
            }
          }
        },
        series: [
          {
            data: [],
            type: "bar",
            barGap: "0%",
            barWidth: 15,
            color: '#2366fc',
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = [
                    'rgb(106, 240, 177)',
                    'rgb(9, 117, 240)',
                    'rgb(227, 233, 240)',
                    'rgb(247, 183, 56)',
                    'rgb(137, 240, 106)',
                    'rgb(245, 138, 221)',
                    'rgb(224, 85, 85)',
                    '#12b7ee',
                    '#f035a5'
                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },

                // 渐变色
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                //   [
                //     {
                //       offset: 0,
                //       color: "rgba(0,244,255,1)" // 0% 处的颜色
                //     },
                //     {
                //       offset: 1,
                //       color: "rgba(0,77,167,1)" // 100% 处的颜色
                //     }
                //   ],
                //   false
                // ),
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4
              }
            },
            // 标签
            label: {
              show: true,
              position: "top",
              distance: 10,
              fontSize: 16,
              color: '#fff',
              fontWeight: 500
            }
          }
        ],
      },
    };
  },
  watch: {
    value(val) {
      if (val.xRotate) {
        this.options.xAxis.axisLabel.rotate = 0
      }
      this.options.xAxis.data = val.x
      this.options.yAxis.data = val.y
      this.options.series[0].data = val.y
      this.options.yAxis.name = val.yName
      this.unit = val.unit
    }
  },
  mounted() {
    // let val = JSON.parse(this.$route.params.val)
    // this.options.title.text = val.title
    // this.options.xAxis.data = val.x
    // this.options.yAxis.data = val.y
    // this.options.yAxis.name = val.yName
    // this.options.series[0].data = val.y
  },
  methods: {
    clickChart(params) {
      this.$emit('emitChartParams', params.name)
    }
  }
};
</script>

<style scoped lang="less">
.s { color: #f035a5; }
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.chart_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unit { position: absolute; left: 10px; bottom: 0px; color: #fff; font-size: 14px; }
</style>
