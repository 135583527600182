module.exports  = {
  // ------------------------ 开发环境 ------------------
  // baseURL: '/dsj/api',


  // -------------------------- 生产环境 ---------------------
  baseURL: '/api',

  // file Path
  filePath: ''
}