<template>
  <div class="full">
    <!-- <v-chart ref="map" :options="options" autoresize @click="clickMap" @dblclick="dblClickMap" /> -->
    <div class="full" ref="wordMap"></div>
  </div>
</template>

<script>
import { color } from 'echarts';
import './world.js'
import { getCountryList } from '@/api/base/index.js'

export default {
  name: "WorldMap",
  props: {
    heightLightArea: []
  },
  data() {
    return {
      wordMap: null,
      mapData: [],
      options: {
        //backgroundColor: '#0F1C3C',
        tooltip: {
          show: false,
          trigger: "item",
          formatter: function(params) {
            if (params.value.length > 1) {
              return params.data.name;
            } else {
              return params.name;
            }
          },
        },
        grid: {
          left: "0%",
          right: "0%",
          top: "0%",
          bottom: "0%",
          containLabel: true,
        },
        geo: {
          map: "world",
          show: true,
          // left: 120,
          // top: 0,
          roam: false,
          zoom: false,
          silent: false,
          selectedMode: false,
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 1.1, //缩放最小大小
            max: 20, //缩放最大大小
          },
          aspectScale: 1,
          // label: { emphasis: {  show: false,  } },
          layoutSize: "100%",
          layoutCenter: ['50%', '50%'],
          itemStyle: {
            normal: {
              // borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1,
              //   [{ offset: 0, color: "#c5cbee" }, { offset: 1, color: "#c5cbee" }],
              //   false
              // ),
              borderColor: '#eee',
              color: '#c5cbee',
              borderWidth: 1,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 10,
            },
          },
          // emphasis: {
          //   disabled: false,
          //   focus: 'none'
          // },
        },
        series: [
          {
            type: "map",
            colorBy: 'series',
            zlevel: 0,
            map: "world",
            aspectScale: 1,
            roam: false,
            zoom: false,
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 1.1, //缩放最小大小
              max: 20, //缩放最大大小
            },
            layoutSize: "100%",
            layoutCenter: ['49.7%', '49.4%'],
            label: {
              normal: {
                //调整数值
                position: "right",
                show: false,
                color: "#53D9FF",
                fontSize: 16,
              },
              rich: {
                pic:{
                  backgroundColor: {
                    // image: require('@/assets/country-img/xx.svg') 
                  },
                  width: 30,
                  height: 30,
                  align:'center',
                },
                fline: {
                  color: '#FFDC6C',
                  align:'center'
                },
                tline: {
                  color: '#FFDC6C',
                  align:'center'
                },
              },
              // emphasis: {
              //   show: true,
              //   focus: 'none'
              // },
            },
            // 地图区域颜色
            itemStyle: {
              //地图块颜色
              areaColor: '#2683f6',
              borderColor: "#eee",
              borderType: 'solid',
              borderWidth: 1,
              // shadowColor: 'rgba(0, 0, 0, 0.5)',
              // shadowBlur: 0,
              opacity: 1,
              regions: {
                selected: false,
              },
            },
            //鼠标放置颜色加深
            emphasis: {
              label: {
                color: '#000'
              },
              itemStyle: {
                areaColor: '#fff',
              },
              focus: 'none'
            },
            selectedMode: 'multiple', 
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    heightLightArea: {
      handler(val) {
        this.clearHeightLight()

        if (val.length) {
          setTimeout(() => {
            this.heightLightMap(val)
          }, 200)
        }
      }
    }
  },
  mounted() {
    this.initMap()
    this.getCountryList()
  },
  methods: {
    initMap() {
      this.wordMap = this.$echarts.init(this.$refs.wordMap)
    },

    // 获取 所有 国家， 生成 map 数据
    async getCountryList() {
      let data = []
      let res = await getCountryList()
      try {
        if (res.code == 1000) {
          for(let i in res.data) {
            let item = {}

            if (res.data[i].chineseName == '中国') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: '#ce1126',
                  },
                  label: {
                    show: true,
                    color: '#FAF408'
                  }
                }
              }
            } else if (res.data[i].chineseName == '俄罗斯') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: 'rgb(170, 218, 247)'
                  },
                  label: {
                    show: true,
                    color: '#000'
                  }
                }
              }
            } else {
              item = { 
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: 'rgb(251, 230, 4)',
                  },
                  label: {
                    show: true,
                    color: '#000'
                  }
                }
              }
            }
            
            data.push(item)
          }
          this.mapData = data
          this.options.series[0].data = data

          this.wordMap.setOption(this.options, true)
        }
      } catch(err) {
        console.log('获取国家列表失败', err);
      }
    },

    clickMap(params) {
      // console.log('点击的国家 : ', params.name);

      this.$emit('selectArea', params.name)
    },

    heightLightMap(nameList) {
      let mapData = this.mapData
      for (let i in nameList) {
        for (let j in mapData) {
          if (mapData[j].name == nameList[i]) {
            mapData[j].selected = true
          }
        }
      }

      this.options.series[0].data = mapData
      this.wordMap.setOption(this.options, true)
    },

    // 取消高亮
    clearHeightLight() {
      let mapData = this.options.series[0].data
      for(let i in mapData) {
        mapData[i].selected = false
      }

      this.wordMap.setOption(this.options, true)
    }
  }
};
</script>

<style>
  .echarts { width: 100% !important; height: 100% !important; }
  .s { color:rgb(251, 230, 4); }
</style>
