<template>
  <div class="full">
    <!-- <v-chart ref="map" :options="options" autoresize @click="clickMap" @dblclick="dblClickMap" /> -->
    <div class="full" ref="wordMap"></div>
  </div>
</template>

<script>
import './world.js'
import { getCountryList } from '@/api/base/index.js'

export default {
  name: "WorldMap",
  props: {
    heightLightArea: [],
    lines: [],
    effectScatter: []
  },
  data() {
    return {
      wordMap: null,
      capitalCoordinates: {
        "阿富汗": [69.11,34.28],
        "阿尔巴尼亚": [19.49,41.18],
        "阿尔及利亚": [3.08,36.42],
        "美属萨摩亚": [-170.43,-14.16],
        "安道​​尔": [1.32,42.31],
        "安哥拉": [13.15,-8.50],
        "安提瓜和巴布达": [-61.48,17.20],
        "阿根廷": [-60.00,-36.30],
        "亚美尼亚": [44.31,40.10],
        "阿鲁巴": [-70.02,12.32],
        "澳大利亚": [149.08,-35.15],
        "奥地利": [16.22,48.12],
        "阿塞拜疆": [49.56,40.29],
        "巴哈马": [-77.20,25.05],
        "巴林": [50.30,26.10],
        "孟加拉国": [90.26,23.43],
        "巴巴多斯": [-59.30,13.05],
        "白俄罗斯": [27.30,53.52],
        "比利时": [4.21,50.51],
        "伯利兹": [-88.30,17.18],
        "贝宁": [2.42,6.23],
        "不丹": [89.45,27.31],
        "玻利维亚": [-68.10,-16.20],
        "波斯尼亚和黑塞哥维那": [18.26,43.52],
        "博茨瓦纳": [25.57,-24.45],
        "巴西": [-47.55,-15.47],
        "英属维尔京群岛": [-64.37,18.27],
        "文莱": [115.00,4.52],
        "保加利亚": [23.20,4245],
        "布基纳法索": [-1.30,12.15],
        "布隆迪": [29.18,-3.16],
        "柬埔寨": [104.55,11.33],
        "喀麦隆": [11.35,3.50],
        "加拿大": [-75.42,45.27],
        "佛得角": [-23.34,15.02],
        "开曼群岛": [-81.24,19.20],
        "中非共和国": [18.35,4.23],
        "乍得": [14.59,12.10],
        "智利": [-70.40,-33.24],
        "中国": [116.20,39.55],
        "中国香港": [114.18107,22.347104],
        "中国台湾": [121.576784,25.107685],
        "哥伦比亚": [-74.00,4.34],
        "科摩罗": [43.16,-11.40],
        "刚果": [15.12,-4.09],
        "哥斯达黎加": [-84.02,9.55],
        "科特迪瓦": [-5.17,6.49],
        "克罗地亚": [15.58,45.50],
        "古巴": [-82.22,23.08],
        "塞浦路斯": [33.25,35.10],
        "捷克共和国": [14.22,50.05],
        "捷克": [14.22,50.05],
        "朝鲜": [125.30,39.09],
        "刚果(扎伊尔)": [15.15,-4.20],
        "丹麦": [12.34,55.41],
        "吉布提": [42.20,11.08],
        "多米尼加": [-61.24,15.20],
        "多米尼加共和国": [-69.59,18.30],
        "东帝汶": [125.34,-8.29],
        "厄瓜多尔": [-78.35,-0.15],
        "埃及": [31.14,30.01],
        "萨尔瓦多": [-89.10,13.40],
        "赤道几内亚": [8.50,3.45],
        "厄立特里亚": [38.55,15.19],
        "爱沙尼亚": [24.48,59.22],
        "埃塞俄比亚": [38.42,9.02],
        "福克兰群岛(马尔维纳斯群岛)": [-59.51,-51.40],
        "法罗群岛": [-6.56,62.05],
        "斐济": [178.30,-18.06],
        "芬兰": [25.03,60.15],
        "法国": [2.20,48.50],
        "法属圭亚那": [-52.18,5.05],
        "法属波利尼西亚": [-149.34,-17.32],
        "加蓬": [9.26,0.25],
        "冈比亚": [-16.40,13.28],
        "格鲁吉亚": [44.50,41.43],
        "德国": [13.25,52.30],
        "加纳": [-0.06,5.35],
        "希腊": [23.46,37.58],
        "格陵兰": [-51.35,64.10],
        "瓜德罗普岛": [-61.44,16.00],
        "危地马拉": [-90.22,14.40],
        "根西岛": [-2.33,49.26],
        "几内亚": [-13.49,9.29],
        "几内亚比绍": [-15.45,11.45],
        "圭亚那": [-58.12,6.50],
        "海地": [-72.20,18.40],
        "赫德岛和麦当劳群岛": [74.00,-53.00],
        "洪都拉斯": [-87.14,14.05],
        "匈牙利": [19.05,47.29],
        "冰岛": [-21.57,64.10],
        "印度": [77.13,28.37],
        "印度尼西亚": [106.49,-6.09],
        "伊朗": [51.30,35.44],
        "伊拉克": [44.30,33.20],
        "爱尔兰": [-6.15,53.21],
        "以色列": [35.12,31.47],
        "意大利": [12.29,41.54],
        "牙买加": [-76.50,18.00],
        "约旦": [35.52,31.57],
        "哈萨克斯坦": [71.30,51.10],
        "肯尼亚": [36.48,-1.17],
        "基里巴斯": [173.00,1.30],
        "科威特": [48.00,29.30],
        "吉尔吉斯斯坦": [74.46,42.54],
        "老挝": [102.36,17.58],
        "拉脱维亚": [24.08,56.53],
        "黎巴嫩": [35.31,33.53],
        "莱索托": [27.30,-29.18],
        "利比里亚": [-10.47,6.18],
        "阿拉伯利比亚民众国": [13.07,32.49],
        "列支敦士登": [9.31,47.08],
        "立陶宛": [25.19,54.38],
        "卢森堡": [6.09,49.37],
        "马达加斯加": [47.31,-18.55],
        "马拉维": [33.48,-14.00],
        "马来西亚": [101.41,3.09],
        "马尔代夫": [73.28,4.00],
        "马里": [-7.55,12.34],
        "马耳他": [14.31,35.54],
        "马提尼克岛": [-61.02,14.36],
        "毛里塔尼亚": [57.30,-20.10],
        "马约特岛": [45.14,-12.48],
        "墨西哥": [-99.10,19.20],
        "密克罗尼西亚(联邦) ": [158.09,6.55],
        "摩尔多瓦共和国": [28.50,47.02],
        "莫桑比克": [32.32,-25.58],
        "缅甸": [96.20,16.45],
        "蒙古": [106.877344,48.067748],
        "纳米比亚": [17.04,-22.35],
        "尼泊尔": [85.20,27.45],
        "荷兰": [4.54,52.23],
        "荷属安的列斯": [-69.00,12.05],
        "新喀里多尼亚": [166.30,-22.17],
        "新西兰": [174.46,-41.19],
        "尼加拉瓜": [-86.20,12.06],
        "尼日尔": [2.06,13.27],
        "尼日利亚": [7.32,9.05],
        "诺福克岛": [168.43,-45.20],
        "北马里亚纳群岛": [145.45,15.12],
        "挪威": [10.45,59.55],
        "阿曼": [58.36,23.37],
        "巴基斯坦": [73.10,33.40],
        "帕劳": [134.28,7.20],
        "巴拿马": [-79.25,9.00],
        "巴布亚新几内亚": [147.08,-9.24],
        "巴拉圭": [-57.30,-25.10],
        "秘鲁": [-77.00,-12.00],
        "菲律宾": [121.03,14.40],
        "波兰": [21.00,52.13],
        "葡萄牙": [-9.10,38.42],
        "波多黎各": [-66.07,18.28],
        "卡塔尔": [51.35,25.15],
        "韩国": [126.58,37.31],
        "罗马尼亚": [26.10,44.27],
        "俄罗斯": [37.35,55.45],
        "卢旺达": [30.04,-1.59],
        "圣基茨和尼维斯": [-62.43,17.17],
        "圣卢西亚": [-60.58,14.02],
        "圣皮埃尔和密克隆": [-56.12,46.46],
        "圣文森特和格林纳丁斯": [-61.10,13.10],
        "萨摩亚": [-171.50,-13.50],
        "圣马力诺": [12.30,43.55],
        "圣多美和普林西比": [6.39,0.10],
        "沙特阿拉伯": [46.42,24.41],
        "塞内加尔": [-17.29,14.34],
        "塞拉利昂": [-13.17,8.30],
        "塞尔维亚": [20.426344,44.850907],
        "斯洛伐克": [17.07,48.10],
        "斯洛文尼亚": [14.33,46.04],
        "所罗门群岛": [159.57,-9.27],
        "索马里": [45.25,2.02],
        "比勒陀利亚": [28.12,-25.44],
        "西班牙": [-3.45,40.25],
        "苏丹": [32.35,15.31],
        "苏里南": [-55.10,5.50],
        "斯威士兰": [31.06,-26.18],
        "瑞典": [18.03,59.20],
        "瑞士": [7.28,46.57],
        "日本": [139.76041,35.723657],
        "阿拉伯叙利亚共和国": [36.18,33.30],
        "塔吉克斯坦": [68.48,38.33],
        "泰国": [100.35,13.45],
        "马其顿": [21.26,42.01],
        "多哥": [1.20,6.09],
        "汤加": [-174.00,-21.10],
        "突尼斯": [10.11,36.50],
        "土耳其": [32.54,39.57],
        "土库曼斯坦": [57.50,38.00],
        "图瓦卢": [179.13,-8.31],
        "乌干达": [32.30,0.20],
        "乌克兰": [30.28,50.30],
        "阿联酋": [54.22,24.28],
        "英国": [-0.05,51.36],
        "坦桑尼亚": [35.45,-6.08],
        "美国": [-77.02,39.91],
        "美属维尔京群岛": [-64.56,18.21],
        "乌拉圭": [-56.11,-34.50],
        "乌兹别克斯坦": [69.10,41.20],
        "瓦努阿图": [168.18,-17.45],
        "委内瑞拉": [-66.55,10.30],
        "越南": [105.55,21.05],
        "南斯拉夫": [20.37,44.50],
        "赞比亚": [28.16,-15.28],
        "津巴布韦": [31.02,-17.43]
      },
      mapData: [],
      options: {
        //backgroundColor: '#0F1C3C',
        tooltip: {
          show: false,
          trigger: "item",
          formatter: function(params) {
            if (params.value.length > 1) {
              return params.data.name;
            } else {
              return params.name;
            }
          },
        },
        grid: {
          left: "0%",
          right: "0%",
          top: "0%",
          bottom: "0%",
          containLabel: true,
        },
        geo: {
          map: "world",
          show: false,
          // left: 120,
          // top: 0,
          roam: false,
          zoom: false,
          silent: false,
          selectedMode: false,
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 2, //缩放最小大小
            max: 20, //缩放最大大小
          },
          aspectScale: 1,
          // label: { emphasis: {  show: false,  } },
          layoutSize: "100%",
          layoutCenter: ['50%', '50%'],
          itemStyle: {
            normal: {
              // borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1,
              //   [{ offset: 0, color: "#c5cbee" }, { offset: 1, color: "#c5cbee" }],
              //   false
              // ),
              borderColor: '#eee',
              color: '#c5cbee',
              borderWidth: 1,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 10,
            },
          },
          // emphasis: {
          //   disabled: false,
          //   focus: 'none'
          // },
        },
        series: [
          {
            type: "map",
            colorBy: 'series',
            zlevel: 0,
            map: "world",
            aspectScale: 1,
            roam: false,
            zoom: false,
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 2, //缩放最小大小
              max: 20, //缩放最大大小
            },
            layoutSize: "100%",
            layoutCenter: ['50%', '50%'],
            label: {
              normal: {
                //调整数值
                position: "right",
                show: false,
                color: "#53D9FF",
                fontSize: 16,
              },
              rich: {
                pic:{
                  backgroundColor: {
                    // image: require('@/assets/country-img/xx.svg') 
                  },
                  width: 30,
                  height: 30,
                  align:'center',
                },
                fline: {
                  color: '#FFDC6C',
                  align:'center'
                },
                tline: {
                  color: '#FFDC6C',       
                  align:'center'
                },
              },
              // emphasis: {
              //   show: true,
              //   focus: 'none'
              // },
            },
            // 地图区域颜色
            itemStyle: {
              //地图块颜色
              // areaColor: '#2683f6',
              areaColor: {
                type: 'linear',
                colorStops: [
                  { offset: 0, color: '#2558c5'}, 
                  { offset: 1, color: '#539ef9'}
                ],
              },
              borderColor: "#2558c5",
              borderType: 'solid',
              borderWidth: 1,
              shadowColor: 'rgb(223, 236, 247)',   
              shadowBlur: 0,
              shadowOffsetx: 0,
              shadowOffsetY: 0,
              opacity: 1,
              regions: {
                selected: false,
              },
            },
            //鼠标放置颜色加深
            emphasis: {
              label: {
                color: '#000'
              },
              itemStyle: {
                areaColor: '#fff',
              },
              focus: 'none'
            },
            selectedMode: 'multiple', 
            silent: true,
            data: [],
          },
          {
            type: 'lines',
            colorBy: 'data',
            coordinateSystem: 'geo',
            polyline: false,  // false: 两个节点中只有一段线， true: 两个节点中 可以有多段线 
            zlevel: 5,
            effect: {
              show: true,
              color: 'rgb(96, 248, 25)',
              loop: true,
              roundTrip: false,
              period: 5, //箭头指向速度，值越小速度越快
              trailLength: 0, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'arrow', //ECharts 提供的标记类型包括 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
              symbolSize: 10, //图标大小
            },
            label: {
              show: false,
              color: '#fff',
              fontStyle: 'normal',
              fontSize: '20',
              position: 'middle',
              distance: 1,
              formatter: '{@value}',
            },
            labelLine: {
              show: false,
              showAbove: true,
              length2: 10,
              smooth: true,
              minTurnAngle: 10,
              lineStyle: {
                color: '#f00'
              }
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#eee',
              borderWidth: 2,
              borderType: 'sollid',
            },
            lineStyle: {
              color: '#ff0',
              width: 3, //尾迹线条宽度
              type: 'solid',
              opacity: 1, //尾迹线条透明度
              curveness: 0.3 //尾迹线条曲直度
            },
            areaStyle: {
              color: '#f00',
              origin: 'auto',
            },
            emphasis: {
              disabled: false,
              scale: true,
              focus: 'series',
              lineStyle: {
                color: '#ff0',
              },
              label: {
                show: true,
              },
              lineStyle: {
                color: '#fff',
              },
              areaStyle: {
                color: '#fff',
              }
            },
            selectedMode: true,
            select: {
              disabled: false,
              lineStyle: {
                color: '#fff',
              },
              label: {
                show: true,
              },
            },
            data: [],
            // markPoint:{
            //   symbol: 'circle', //ECharts 提供的标记类型包括 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
            //   symbolSize: 8, //图标大小
            // }
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',// 指定使用地理坐标系
            data: [],
            encode: {
              value: 2
            },
            symbolSize: 6,
            rippleEffect: {
              color: 'rgb(221, 250, 2)',
              brushType: 'stroke',
              scale: 7
            },
            hoverAnimation: true,
            itemStyle: {
              color: '#f4e925',
              shadowBlur: 20,
              shadowColor: '#333',
              borderWidth: 5,
              borderColor: '#f00',
              borderType: 'dashed'
            },
            zlevel: 1
          }
        ],
      },
    };
  },
  watch: {
    heightLightArea: {
      handler(countryList) {
        this.clearHeightLight()

        if (countryList.length) {
          for (let i in countryList) {
            if (countryList[i] == '中国香港' || countryList[i] == '中国台湾') {
              countryList[i] = '中国'
            }
          } 

          setTimeout(() => {
            this.heightLightMap(countryList)
          }, 200)
        }
      }
    },

    lines: {
      handler(list) {
        let linesData = []

        for (let i in list) {
          let item = {
            coords: [ this.capitalCoordinates[list[i].form], this.capitalCoordinates[list[i].to]],
            value: list[i].value
          }

          linesData.push(item)
        }

        this.options.series[1].data = linesData

        this.wordMap.setOption(this.options, true)
      },
    },

    effectScatter: {
      handler(list) {
        let effectScatterData = []
        for (let i in list) {
          let item = { value: this.capitalCoordinates[list[i]] }
          effectScatterData.push(item)
        }

        this.options.series[2].data = effectScatterData

        this.wordMap.setOption(this.options, true)
      }
    }
  },
  mounted() {
    this.initMap()

    // 获取 世界 全部国家
    this.getCountryList()

    // 显示飞线
    // this.showLines()

    // 显示涟漪
    // this.showEffectScatter()

    // this.wordMap.setOption(this.options, true)
  },
  methods: {
    initMap() {
      this.wordMap = this.$echarts.init(this.$refs.wordMap)
    },

    // 获取 所有 国家， 生成 map 数据
    async getCountryList() {
      let data = []
      let res = await getCountryList()
      try {
        if (res.code == 1000) {
          for(let i in res.data) {
            let item = {}

            if (res.data[i].chineseName == '中国') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: '#ce1126',
                  },
                  label: {
                    show: true,
                    color: '#FAF408'
                  }
                }
              }
            } else if (res.data[i].chineseName == '俄罗斯') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: 'rgb(170, 218, 247)'
                  },
                  label: {
                    show: true,
                    color: '#000'
                  }
                }
              }
            } else if (res.data[i].chineseName == '澳大利亚') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: '#07c3f8'
                  },
                  label: {
                    show: true,
                    color: '#000'
                  }
                }
              }
            } else if (res.data[i].chineseName == '巴西') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: 'rgb(251, 230, 4)'
                  },
                  label: {
                    show: true,
                    color: '#000'
                  }
                }
              }
            } else if (res.data[i].chineseName == '美国') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: '#3C3B6E'
                  },
                  label: {
                    show: true,
                    color: '#fff'
                  }
                }
              }
            } else if (res.data[i].chineseName == '英国') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: 'rgb(28, 31, 100)'
                  },
                  label: {
                    show: true,
                    color: '#fff'
                  }
                }
              }
            } else if (res.data[i].chineseName == '日本') {
              item = {
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: '#fff'
                  },
                  label: {
                    show: true,
                    color: '#f00'
                  }
                }
              }
            } else {
              item = { 
                id: res.data[i].id, 
                name: res.data[i].chineseName,
                selected: false,
                select: {
                  disabled: false,
                  itemStyle: {
                    areaColor: 'rgb(83, 245, 8)',
                  },
                  label: {
                    show: true,
                    color: '#000'
                  }
                }
              }
            }
            
            data.push(item)
          }
          this.mapData = data
          this.options.series[0].data = data

          this.wordMap.setOption(this.options, true)
        }
      } catch(err) {
        console.log('获取国家列表失败', err);
      }
    },

    // 点击 国家区域
    clickMap(params) {
      // console.log('点击的国家 : ', params.name);
      this.$emit('selectArea', params.name)
    },

    // 高亮 区域
    heightLightMap(nameList) {
      let mapData = this.mapData

      // let nameList = ['美国', '巴西', '中国', '俄罗斯', '澳大利亚']
      for (let i in nameList) {
        for (let j in mapData) {
          if (mapData[j].name == nameList[i]) {
            mapData[j].selected = true
          }
        }
      }

      this.options.series[0].data = mapData
      this.wordMap.setOption(this.options, true)
    },

    // 取消高亮
    clearHeightLight() {
      let mapData = this.options.series[0].data
      for(let i in mapData) {
        mapData[i].selected = false
      }

      this.wordMap.setOption(this.options, true)
    },

    // 显示飞线
    showLines() {
      let linesData = [
        {
          coords: [
            this.capitalCoordinates['美国'], 
            this.capitalCoordinates['中国'], 
          ],
          value: 193
        },
        {
          coords: [
            this.capitalCoordinates['美国'], 
            this.capitalCoordinates['巴西'], 
          ],
          value: 189
        },
        {
          coords: [
            this.capitalCoordinates['美国'], 
            this.capitalCoordinates['俄罗斯'], 
          ],
          value: 189
        },
        {
          coords: [
            this.capitalCoordinates['美国'], 
            this.capitalCoordinates['澳大利亚'], 
          ],
          value: 189
        }
      ]

      this.options.series[1].data = linesData
    },

    // 显示涟漪
    showEffectScatter() {
      let effectScatterData = [
        {value: this.capitalCoordinates['美国']},
        {value: this.capitalCoordinates['中国']},
        {value: this.capitalCoordinates['巴西']},
        {value: this.capitalCoordinates['俄罗斯']},
        {value: this.capitalCoordinates['澳大利亚']}
      ]
      this.options.series[2].data = effectScatterData
    }
  }
};
</script>

<style>
  .echarts { width: 100% !important; height: 100% !important; }
  .s { color: rgb(28, 31, 100) }
</style>
