<template>
  <div class="box">
    <div class="chart_box">
      <v-chart :options="options" autoresize @click="clickChart" />
    </div>

    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>
const labelOption = {
  show: true,
  rotate: 90,
  formatter: '{c}  {name|{a}}',
  fontSize: 16,
  rich: {
    name: {}
  }
};
export default {
  name: "ShuangZhuTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isShowUnit: true,
    isShowLegend: true,
    isShowXData: true
  },
  data() {
    return {
      animalList: [],
      unit: '',
      options: {
        title: {
          text: '',
          top: '',
          left: '40px',
          show: false,
          textStyle: {
            color: '#fff',
            fontSize: '30px'
          }
        },
        grid: {
          left: "2%",
          right: "0%",
          top: "5%",
          bottom: "0%",
          // y 轴 分类名称 全部显示
          containLabel: true,
          show: true,
          z: 0,
          // backgroundColor: "rgba(0,0,0,0)",
          // borderWidth: 1,
          // borderColor: "#f00"
        },
        legend: {
          top: 0,
          left: 100,
          textStyle: {
            color: '#000'
          }
        },
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
        },
        xAxis: {
          type: "value",
          // 柱子百分比 长短
          boundaryGap: [0, 0.5],
          data: [],
          name: '',
          splitLine: false,
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff",
              fontSize: 20
            }
          }
        },
        yAxis: {
          type: "category",
          data: [],
          boundaryGap: true,
          triggerEvent: true,
          interval: 100,
          axisLabel: {    // 坐标轴刻度标签 设置
            show: true,
            height: 100,
            // lineHeight: 100,
            fontStyle: 'italic',
            fontFamily: 'Microsoft YaHei',
            textStyle: {
              color: "#000",
              fontSize: 14,
              fontWeight: '500'
            },
          },
          axisTick: {     // 坐标轴刻度 设置
            alignWithLabel: false,
            // interval: "auto",
            show: true,
            inside: false,
            length: 5,
          },
          minorTick: {
            show: true,
            length: 500,
          },
          z: 0,
          inverse: false,
          name: "",
          nameLocation: "end",
          nameRotate: null,
          nameGap: 15,
          silent: false,
          triggerEvent: false,
          // deduplication: null,
          // nameGap: 150,
          tooltip: {
            show: true
          }

        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 7,
            barGap: "200%",
            barCategoryGap: "300%",
            barMinAngle: 300,
            color: '#0874EF',
            itemStyle: {
              normal: {
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                // shadowBlur: 2,
                // 圆角
                // barBorderRadius: [0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            // 标签
            label: {
              show: true,
              position: "right",
              distance: 10,
              color: '#fff',
              fontWeight: 600,
              ellipsis: '...',
              formatter: function(params) {
                function numberWithCommas(x) {
                  x = x.toString();
                  var pattern = /(-?\d+)(\d{3})/;
                  while (pattern.test(x))
                    x = x.replace(pattern, "$1,$2");
                  return x;
                }
                let val = numberWithCommas(params.value)
                return `{span|${val}}`
              },
              rich: {
                span: {
                  color: '#000',
                  fontSize: '15px',
                  fontWeight: '500',
                  fontFamily: 'font_number1',
                  letterSpacing: '4px'
                }
              }
            }
          },
          {
            data: [],
            type: "bar",
            barWidth: 7,
            barGap: "200%",
            barCategoryGap: "300%",
            barMinAngle: 300,
            color: '#d21e42',
            itemStyle: {
              normal: {
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                // shadowBlur: 2,
                // 圆角
                // barBorderRadius: [0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            // 标签
            label: {
              show: true,
              position: "right",
              distance: 10,
              color: '#fff',
              fontWeight: 600,
              fontStyle: 'italic',
              ellipsis: '...',
              formatter: function(params) {
                function numberWithCommas(x) {
                  x = x.toString();
                  var pattern = /(-?\d+)(\d{3})/;
                  while (pattern.test(x))
                    x = x.replace(pattern, "$1,$2");
                  return x;
                }
                let val = numberWithCommas(params.value)
                return `{span|${val}}`
              },
              rich: {
                span: {
                  color: '#000',
                  fontSize: '15px',
                  fontWeight: '500',
                  // fontFamily: 'font_number1',
                  letterSpacing: '4px'
                }
              }
            },
          }
        ],
        // 提示
        tooltip: {
          trigger: "axis",
          show: false,
          textStyle: {
            type: 'cross'
          }
        }
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        this.unit = val.unit
        this.options.yAxis.data = val.x
        this.options.series[0].name = val.data1.name
        this.options.series[0].data = val.data1.data

        this.options.series[1].name = val.data2.name
        this.options.series[1].data = val.data2.data
      },
      deep: true,
      immediate: true
    } 
  },
  mounted() {

  },
  methods: {
    clickChart(params) {
      let id = ''

      this.animalList.forEach(item => {
        if (params.name == item.name) {
          id = item.id
        }
      })

      let obj = {
        id,
        name: params.name
      }

      this.$emit('emitChartParams', obj)
    }
  }
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100% !important;
  overflow: hidden;
  // border: 1px solid #000;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.chart_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid #f00;
}

.unit { position: absolute; left: 10px; top: 2px; color: #000; font-size: 14px; }

.s { color: #d21e42; }

</style>
