// echarts 图表
import BingTu from '@/common/charts/bing-tu.vue';
import BanBingTu from '@/common/charts/ban-bing-tu.vue';
import HuanZhuangTu from '@/common/charts/huan-zhuang-tu.vue';
import ZhuZhuangTu from '@/common/charts/zhu-zhuang-tu.vue';
import ZhuZhuangTuX from '@/common/charts/zhu-zhuang-tu-x.vue';
import ShuangZhuTu from '@/common/charts/shuang-zhu-tu.vue';
import SanZhuTu from '@/common/charts/san-zhu-tu.vue';
import XianXingTu from '@/common/charts/xian-xing-tu.vue';
import DuoXianTu from '@/common/charts/duo-xian-tu.vue';
import LeiDaTu from '@/common/charts/lei-da-tu.vue';

export default {
  components: {
    BingTu, 
    BanBingTu, 
    HuanZhuangTu,
    ZhuZhuangTu,
    ZhuZhuangTuX,
    ShuangZhuTu,
    SanZhuTu,
    XianXingTu,
    DuoXianTu,
    LeiDaTu
  }
}