import { filePath } from '@/const.js'

export default {
  data() {
    return {
      filePath: filePath
    }
  },
  methods: {
    // 鼠标滚轮控制 成长记录 TabName 横向滚动
    wheelScroll(e) {
      let box = this.$refs.tabName
      e.preventDefault()
      box.scrollLeft += e.deltaY
    },

    // 格式化 日期
    formatDate(val) {
      if (val) {
        return val.substring(0, 10)
      }
    },

    // 格式化手机号 175 **** 4435
    formatPhone(val) {
      if (val) {
        return val.substring(0, 3) + '****' + val.substring(7, 12)
      }
    },

    // 格式化身份证号 15332219980117401X **** 4435
    formatIdCart(val) {
      if (val) {
        return val.substring(0, 6) + '********' + val.substring(13, 17)
      }
    },

    // 数字 格式化 千分位
    formatNumberWithCommas(x) {
      x = typeof(x) == 'Number' ? x.toString() : x;
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
      return x;
    },

    // 格式化 存栏、防疫 数据 
    // ************************************ 之后需要 修改 ***********************************
    convertData(data, season, year) {
      // let data2 = data.filter(item => {
      //   if (item && item.jobName !== null) {
      //     item.jobName.indexOf(season) >= 0 && item.timeYear == year && item.animalName !== '山羊'
      //   }
      // })

      // debugger

      let data1 = []
      for(let i in data) {
        if (data[i] && data[i].jobName !== null) {
          if (data[i].jobName.indexOf(season) >= 0 && data[i].timeYear == year && data[i].animalName !== '山羊') {
            data1.push(data[i])
          }
        }
      }

      // debugger

      let sortdict = {
        "骆驼": 1,
        "驴": 2,
        "马": 3,
        "犬": 4,
        "禽": 5,
        "猪": 6,
        "羊": 7,
        "牛": 8
      }

      let data2 = data1.sort((a, b) => sortdict[a.animalName] - sortdict[b.animalName])

      let data4 = []
      data2.forEach(item=> { data4.push([item.animalId, item.animalName, item.animalCount, item.fangYiCount, item.timeYear])})
      return data4
    },

    // 格式化 牧户 存栏、防疫 数据 筛选出不重复的 动物防疫信息 
    convertUserData(data, season, year) {
      let animalList = []
      let dataList = []

      if (!animalList.length) {
        animalList.push(data[0].animalName)
      } 

      data.filter(item => {
        if (!animalList.includes(item.animalName)) {
          animalList.push(item.animalName)
          dataList.push(item)
        }
      })

      let data2 = dataList.filter(item => item.jobName.indexOf(season) >= 0 && item.jobName.indexOf(year) >= 0 )
      let data4 = []
      data2.forEach(item=> { data4.push( [item.animalId, item.animalName, item.animalCount, item.fangYiCount, item.jobName])})
      return data4
    },

    // 格式化 牧户 存栏、防疫 数据 ，筛选出不重复的 【牧户】
    convertMuHuData(data) {
      let userNameList = []
      let muHuList = []

      if (!userNameList.length) {
        userNameList.push(data[0].userNickName)
      } 

      data.filter(item => {
        if (!userNameList.includes(item.userNickName)) {
          userNameList.push(item.userNickName)
          muHuList.push(item)
        }
      })

      return muHuList

      // let data2 = dataList.filter(item=> item.jobName.indexOf(season) >= 0 && item.jobName.indexOf(year) >= 0 )
      // let data4 = []
      // data2.forEach(item=> { data4.push( [item.animalId, item.animalName, item.animalCount, item.fangYiCount, item.jobName])})
      // return data4
    },

    // 格式化疫苗数据 
    convertYiMiaoData(data, season){
      let data2 = data.filter(item=> item.typeName == season)
      let data4 = []
      data2.forEach(item=> { data4.push( [item.nameCn])})
      return data4
    },

    // 格式化 性别
    formatGender(val) {
      return val == 0 ? '男' : '女'
    },

    // 格式化 牛 性别
    formatCattleGender(sexValue) {
      return sexValue == 0 ? '公' : '母' 
    },

    // 动物 养殖类别
    formatBreedType(val) {
      let result = ''

      if (val == 0) {
        result = '基础母牛'
      } else if (val == 1) {
        result = '育肥牛'
      }

      return result
    },

    // 格式化 牛 图片,
    formatCattleAvatar(str) {
      let list = str.split(',')

      if (list.length) {
        return this.filePath + list[0]
      } 

      return ''
    },

    // 生成 饼图 数据
    generatePieData(resData) {
      let list = []

      for (let i in resData) {
        let item = { name: resData[i].title, value: resData[i].count, unit: '' }

        list.push(item)
      }         

      return list
    },

    // 生成 柱状图 数据
    generateBarData(resData) {
      let data = {
        x: [], y: []
      }

      for (let i in resData) {
        data.x.push(resData[i].name)
        data.y.push(resData[i].count)
      }

      return data
    },

    // 接收 点击图表后的 参数， 并跳转到 【疫苗详情页】
    receiveYiMiaoParams(id, name, typeId) {
      this.$router.push(`/yiMiaoDetailPage/${id}/${name}/${typeId}`)
    },

    // 跳转到 【疫苗详情页】
    goToVacciumnDetail(id, name, typeId) {
      this.$router.push(`/yiMiaoDetailPage/${id}/${name}/${typeId}`)
    },

    // 点击 防疫员, 跳转到 【防疫员详情页】
    goToFangYiYuanPage(id) {
      this.$router.push({
        path: `/fangYiYuanPage/${id}`
      })
    },

    // 点击 牧户, 跳转到 【牧户详情页】
    goToMuHuPage(townId, villageId, groupId, ownerId) {
      this.$router.push({
        path: `/muHuPage/${townId}/${villageId}/${groupId}/${ownerId}`
      })
    },

    // 点击 疫苗，跳转到 【疫苗详情页】
    goToYiMiaoDetailPage(id) {
      this.$router.push({
        path: `/yiMiaoDetailPage`
      })
    },

  }
}