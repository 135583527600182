<template>
  <div class="full" id="Echart" ref="Echart">
    <div class="full relative">
      <!-- 筛选 -->
      <div class="w_50_p flex_row_end absolute top_-2 right_0 z_999"> 
        <div class="w_25 h_25 mr_10" v-if="isShowFilterIcon">
          <img class="full cursor" src="~@/assets/images/chart-box/filter.png" alt="" @click="showFilterBox = true">
        </div>

        <!-- 全屏 -->
        <div class="w_30 h_30" v-if="isShowFullIcon">
          <img 
            class="full cursor" 
            v-if="isFullscreen" 
            src="~@/assets/images/icon/cancel-full.png" 
            alt="" 
            @click="fullScreen()"
          >

          <img class="full cursor" v-if="!isFullscreen" src="~@/assets/images/icon/full.png" alt="" @click="fullScreen()">
        </div>
      </div>

      <echart 
        :title="title" 
        :path="path" 
        :chartType="chartType" 
        :chartData="chartData" 
        :isShowTitle="isShowTitle"
        :isShowUnit="isShowUnit"
        @emitChartParams="handleClickChart" 
      />

      <!-- 筛选区 -->
      <div 
        class="full absolute top_0 right_0" 
        v-show="showFilterBox"
        @click="showFilterBox = false"
      >
        <div class="w_40_p h_full absolute top_0 right_0 p_10 bgc_f6f6f6 z_999">
          <div class="w_full flex_row_between">
            <div class="w_85_p">
              <el-select 
                class="w_full_i" 
                v-model="filterVal" 
                size="mini" 
                :placeholder="filterText" 
                clearable filterable
              >
                <el-option
                  v-for="(item, index) in filterList" :key="index"
                  :label="item.name"
                  :value="item.id"
                  @click.native="handlefilter(item.id)"
                >
                </el-option>
              </el-select>
            </div>

            <div class="w_15_p relative color_fff fw_bold cursor" @click="showFilterBox = false">
              <div class="w_20 h_20 absolute top_0 right_0">
                <img class="full cursor" src="~@/assets/images/chart-box/cancel.png" alt="">
              </div>
            </div>
          </div>
          
        </div>
      </div>
  </div>
  </div>
</template>

<script>
  import screenfull from 'screenfull'
  import defMixin from '@/mixins/def';
  import Echart from '@/components/div/echart.vue'

  export default {
    name: 'DataChart',
    mixins: [defMixin],
    props: {
      title: '',
      path: '',
      chartType: '',
      chartData: {},
      filterText: '',
      filterId: '',
      filterList: [],
      isShowTitle: null,
      isShowUnit: null,
      isShowFilterIcon: null,
      isShowFullIcon: null
    },
    components: { Echart },
    data() {
      return {
        isFullscreen: false,
        showFilterBox: false,
        filterVal: null,
      };
    },
    created() {

    },
    mounted() {

    },
    watch: {
      filterId: {
        handler: function(newVal) {
          this.filterVal = newVal
        },
        immediate: true
      },
    },
    methods: {
      // 全屏
      fullScreen() {
        // const element = document.getElementById('Echart');//指定全屏区域元素

        let element = this.$refs.Echart
        // 先使用screenfull.enabled方法判断是否支持screenfull

        // 如果不允许进入全屏，发出不允许提示 浏览器不能全屏
        if (!screenfull.isEnabled) {
          this.$message({
            message:'浏览器不能全屏，安装其他版本试试',
            type:'warning'
          })

          return false
        }
        
        this.isFullscreen = !this.isFullscreen
        
        screenfull.request(element)

        // 调用 screenfull.toggle() 可以双向切换全屏与非全屏
        screenfull.toggle()
      },

      // 点击 图标
      handleClickChart(val) {
        this.$emit('clickChart', val)
      },

      // 筛选
      handlefilter(val) {
        this.$emit('filterChartData', val)
      }
    }
  }
</script>

<style>
#Echart:-webkit-full-screen {
  padding: 50px;
  background-color: #faf9f9eb !important;
  /* background: linear-gradient(to right, #ecf2f9a1, #d2dbe46f, #f4f5f6a1); */
}
#Echart:-moz-full-screen {
  padding: 50px;
  background-color: #faf9f9eb !important;
  /* background: linear-gradient(to right, #ecf2f9a1, #d2dbe46f, #f4f5f6a1); */
}
#Echart:-ms-fullscreen {
  padding: 50px;
  background-color: #faf9f9eb !important;
  /* background: linear-gradient(to right, #ecf2f9a1, #d2dbe46f, #f4f5f6a1); */
}
#Echart:fullscreen{
  padding: 50px;
  background-color: #faf9f9eb !important;
  /* background: linear-gradient(to right, #ecf2f9a1, #d2dbe46f, #f4f5f6a1); */
}
</style>