<template>
  <div class="full">
    <div class="w_full flex_row_between" v-if="isShowTitle">
      <div 
        class="w_full-30 text_center text_1 fw_bold cursor fs_20 ls_2 color_000"  
        @click="goToPath(path)"
      >
        {{ title }}
      </div>
    </div>
    
    <div class="w_full h_full-30">
      <bing-tu 
        v-if="chartType == '饼图'" 
        :value="chartData" 
        :isShowUnit="isShowUnit"
        @emitChartParams="emitChartParams" 
        @emitChartParamsMouseOver="emitChartParamsMouseOver" 
      />
      <ban-bing-tu 
        v-if="chartType == '半饼图'" 
        :value="chartData" 
        @emitChartParams="emitChartParams" 
      />
      <huan-zhuang-tu 
        v-if="chartType == '环状图'" 
        :value="chartData" 
        :isShowUnit="isShowUnit"
        @emitChartParams="emitChartParams" 
        @emitChartParamsMouseOver="emitChartParamsMouseOver" 
      />
      <zhu-zhuang-tu 
        v-if="chartType == '柱状图'" 
        :value="chartData" 
        :isShowUnit="isShowUnit"
      />
      <zhu-zhuang-tu-x 
        v-if="chartType == '柱状图3'" 
        :value="chartData" 
        :isShowUnit="isShowUnit"
        @emitChartParams="emitChartParams" 
      />
      <shuang-zhu-tu 
        v-if="chartType == '双柱图'" 
        :value="chartData" 
        :isShowUnit="isShowUnit"
        @emitChartParams="emitChartParams" 
      />
      <san-zhu-tu 
        v-if="chartType == '三柱图'" 
        :value="chartData" 
        :isShowUnit="isShowUnit"
        @emitChartParams="emitChartParams" 
      />
      <xian-xing-tu 
        v-if="chartType == '线性图'" 
        :value="chartData"
        :isShowUnit="isShowUnit"
      />
      <duo-xian-tu 
        v-if="chartType == '多线图'" 
        :value="chartData"
        :isShowUnit="isShowUnit" 
      />
      <lei-da-tu 
        v-if="chartType == '雷达图'" 
        :value="chartData"
      />
    </div>
  </div>
</template>

<script>
  import echartsMixin from '@/mixins/echart.js'

  export default {
    name: "Echarts",
    mixins: [echartsMixin],
    props: {
      title: '',
      title_fs: 'text_1',
      path: '',
      chartType: '',
      bgi: 'default',
      chartData: {},
      isShowTitle: true,
      isShowUnit: true
    },
    data() {
      return {
        
      }
    },
    methods: {
      goToPath(path) {
        this.$router.push({
          path
        })
      },

      // 向父组件 发送 图表参数
      emitChartParams(val) {
        this.$emit('emitChartParams', val)
      },

      // 监听鼠标经过 图标 扇形区域
      emitChartParamsMouseOver(val) {
        this.$emit('emitChartParamsMouseOver', val)
      }
    }
  }
</script>

