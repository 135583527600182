<template>
  <div class="box">
    <div class="chart_box">
      <v-chart :options="options" autoresize @click="clickChart" />
    </div>
    <span class="unit" v-show="isShowUnit">单位: {{ unit }}</span>
  </div>
</template>

<script>
export default {
  name: "page1",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ispreview: Boolean,
  },
  data() {
    return {
      title: "",
      unit: '',
      options: {
        tooltip: {
          trigger: "item",
          show: false,
        },
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
        },
        legend: {
          top: "5%",
          left: "center",
          selectedMode: false,
          textStyle: {
            color: '#fff'
          },
          show: false
        },
        series: [
          {
            type: "pie",
            color:[
              'rgb(106, 240, 177)',
              'rgb(9, 117, 240)',
              'rgb(227, 233, 240)',
              'rgb(247, 183, 56)',
              'rgb(137, 240, 106)',
              'rgb(245, 138, 221)',
              'rgb(224, 85, 85)',
            ],
            radius: ["40%", "60%"],
            center: ["50%", "60%"],
            // adjust the start angle
            startAngle: 180,
            label: {
              color: '#fff',
              fontSize: 18,
              formatter: function(params) {
                return params.name +' '+ `{span|${params.value}}`
              },
              rich: {
                span: {
                  color: '#fff',
                  fontSize: '18px',
                  fontWeight: '500',
                  fontFamily: 'font_number1',
                  letterSpacing: '2px'
                }
              }
            },
            data: [],
            itemStyle: {
              borderRadius: 10,
              borderColor: 'rgb(0, 0, 0, 0)',
              borderWidth: 6,
            },
          },
        ],
      },
    };
  },
  watch: {
    value(val) {
      this.options.series[0].data = val.data;
      let sum = 0
      for (let i in val.data) {
        sum += val.data[i].value
      }

      this.options.series[0].data.push(
        {
          value: sum,
          itemStyle: {
            color: "none",
            decal: {
              symbol: "none",
            }
          },
          label: {
            show: false,
          }
        },
      )
              
      this.unit = val.unit
    },
  },
  mounted() {},
  methods: {
    clickChart(params) {
      this.$emit('emitChartParams', params.data)
    }
  },
};
</script>

<style scoped lang="less">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
}

.chart_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
  box-sizing: border-box;
}

.unit { position: absolute; left: 10px; bottom: 0px; color: #fff; font-size: 14px; }
</style>
